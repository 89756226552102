var searchKeys = [{
  key: "name",
  label: "角色名称",
  placeholder: "请输入",
  required: false,
  rules: [],
  input: true
}, {
  key: 'status',
  label: '状态',
  placeholder: "请选择",
  select: true
}, {
  key: "button",
  label: "",
  placeholder: "",
  button: true
}];
export { searchKeys };